
* {
  font-family: "Encode Sans Expanded", sans-serif;
}

h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: 0.8em;
  margin: 1em 0;
}

.product-edit-icon {
  opacity: 0.7;
  position: absolute;
  right: 2.3rem;
  top: 0.4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar,
.navbar-end,
.navbar-menu,
.navbar-start {
  align-items: stretch !important;
  display: flex !important;
  z-index: 1;
  position: relative;
}

.navbar {
  height: 5%;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: flex !important;
  height: 100%;
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  visibility: visible !important;
  height: 100%;
  margin: 0;
  padding: 0;
}

/* 
map the hierarchy of divs - child div % will fill relative to it's parent
make sure they're all 100% up the chain
when multiple elements divide a page (navbar + map), make sure they add up to 100% 
*/

/* setting this to 90% means every child will fill 90% of the available space
if .App is 1000px, then the next level down, app-container, will fill 900px
*/

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/*use the flex display in column format to preserve stack of divs

/* need to make this div fit into its parent, and not overflow */
/* need to break up my navbar and page container in 90% and 10% to add up to 100%? 

use the flex display in column format to preserve stack of divs*/
#page-container {
  height: 95%;
  display: flex;
  flex-direction: column;
}

#map {
  height: 90%;
}

.accordion-header {
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.accordion-indicator {
  position: absolute;
  font-size: 25px;
  right: 50px;
  top: 0px;
}

.court-status {
  padding: 5px;
}

.mapDropdown {
  background-color: #3498db;
  padding: 10px;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminDropdown {
  padding: 15px;
}

.address-block {
  padding: 10px;
  margin-bottom: 10px;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  top: 0;
  left: 0;
  margin: 15% auto;
  padding-top: 5%;
  padding-bottom: 20%;
  /*transform: translate(-50%, -50%);*/
  width: 90%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  -webkit-animation-name: animatetop;
          animation-name: animatetop;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  /*background-color: rgb(0, 0, 0);  /* Fallback color */
  /* background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

@-webkit-keyframes animatetop {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* if we match the names of the bulma classes we can edit them here */

.modal-background {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
}

.modal-card-head {
  padding: 10px 16px;
  background-color: #3498db;
  color: white;
}

.modal-card-title {
  color: white;
}

.modal-body {
  padding: 2px 16px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  height: 100%;
  width: 100%;
  margin: 45% auto; /* 15% from the top and centered */
  padding: 15px;
  border: 1px solid #888;
  width: 90%;
  /* Could be more or less, depending on screen size - want this to be speific to mobile or desktop */
}

/*when screen hits 786px (desktop) then shrink modal width */
@media only screen and (min-width: 768px) {
  .modal {
    margin: 5% auto;
  }
  .modal-content {
    width: 40%;
    margin: 15% auto;
  }
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  padding-left: 30px;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  margin-bottom: 1px;
}

.about-header {
  padding-top: 30px;
  padding-bottom: 30px;
}

.sensorDiv {
  transition: all 0.2s ease-in-out;
}

.ReactCollapse--collapse {
  max-width: 100%;
  transition: height 500ms;
}

